import React, { useEffect, useState, useRef } from 'react';
import '@stream-io/video-react-sdk/dist/css/styles.css';
import {
  StreamCall,
  StreamVideo,
  StreamVideoClient,
  SpeakerLayout,
  CallControls,
  StreamTheme,
  useCallStateHooks
} from '@stream-io/video-react-sdk';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { formatDuration, intervalToDuration } from 'date-fns';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useSessionTimer = () => {
  const { useCallSession } = useCallStateHooks();
  const session = useCallSession();
  const [remainingMs, setRemainingMs] = useState(Number.NaN);

  useEffect(() => {
    if (!session?.timer_ends_at) return;
    const timerEndAt = new Date(session.timer_ends_at);
    const handle = setInterval(() => {
      const now = new Date();
      const remainingMs = +timerEndAt - +now;
      setRemainingMs(remainingMs);
    }, 500);
    return () => clearInterval(handle);
  }, [session]);

  return remainingMs;
};

const useSessionTimerAlert = (remainingMs, threshold, onAlert) => {
  const didAlert = useRef(false);

  useEffect(() => {
    if (!didAlert.current && remainingMs < threshold) {
      onAlert();
      didAlert.current = true;
    }
  }, [onAlert, remainingMs, threshold]);
};

const SessionTimer = () => {
  const remainingMs = useSessionTimer();
  const [showAlert, setShowAlert] = useState(false);
  useSessionTimerAlert(remainingMs, 5 * 60 * 1000, () => setShowAlert(true));
  return (
    <>
      <div className="session-timer">
        ⏱️{' '}
        {formatDuration(
          intervalToDuration({
            start: Date.now(),
            end: Date.now() + remainingMs,
          }),
        )}
      </div>
      {showAlert && (
        <div className="session-timer-alert">
          Less than 5 minutes remaining
          <button type="button" onClick={() => setShowAlert(false)}>
            Dismiss
          </button>
        </div>
      )}
    </>
  );
};

const Join = () => {
  const query = useQuery();
  const callId = query.get('callID');
  const userId = query.get('uid');
  const [isLoading, setIsLoading] = useState(false);
  const [client, setClient] = useState(null);
  const [call, setCall] = useState(null);
  const [token, setToken] = useState('');
  const [error, setError] = useState('');

  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    if (!userId || !callId) {
      setError("Missing required parameters");
      return; 
    }

    const fetchToken = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post('https://europe-west3-connwy-d15c2.cloudfunctions.net/addParticipant', {
          uid: userId,
          callID: callId
        }, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
        if (response.data && response.data.token && response.data.token.token) {
          setToken(response.data.token.token);
        } else {
          throw new Error("No token returned");
        }
      } catch (error) {
        console.error('Error fetching token:', error);
        setError(`Failed to fetch token: ${error.response ? error.response.data : error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchToken();
  }, [userId, callId]);

  useEffect(() => {
    if (token && apiKey && userId) {
      const user = { id: userId };
      const newClient = new StreamVideoClient({ apiKey, user, token });
      const newCall = newClient.call('default', callId);
      newCall.join({ create: true });

      setClient(newClient);
      setCall(newCall);

      return () => {
        if (newCall) {
          newCall.leave();
        }
      };
    }
  }, [token, apiKey, userId, callId]);

  if (isLoading) {
    return <div className="loader" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!client || !call) {
    return <div>Unable to load the call. Please check your connection and try again.</div>;
  }

  return (
    <StreamVideo client={client}>
      <StreamTheme>
        <StreamCall call={call}>
          <SessionTimer />
          <SpeakerLayout />
          <CallControls />
        </StreamCall>
      </StreamTheme>
    </StreamVideo>
  );
};

export default Join;
