import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Join from './join';

const RedirectToJoin = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const callID = searchParams.get('callID');
  const uid = searchParams.get('uid');

  if (!callID || !uid) {
    console.error("Required parameters are missing from the URL.");
    return <div>Required parameters are missing. Please check the URL and try again.</div>;
  }
  
  if (location.pathname === '/join' && location.search === `?callID=${callID}&uid=${uid}`) {
    return null;
  }

  const newPath = `/join?callID=${callID}&uid=${uid}`;
  return <Navigate replace to={newPath} />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RedirectToJoin />} />
        <Route path="/join" element={<Join />} />
      </Routes>
    </Router>
  );
}

export default App;
